<template>
  <div>
    <div class="custom__collapse" :class="{ active: isCollapseActive }">
      <div class="collapse__header" @click="isCollapseActive = !isCollapseActive">
        <h3 class="pb-0 mb-0 title blue__label font-18">Zusammenfassung</h3>
      </div>
      <div class="collapse__body">
        <insurance-data :data="response" />
      </div>
    </div>
    <div class="my-4">
      <!-- <p class="transport__label">
        „Ich bestätige, dass die Unterschriften des Kunden zum Antrag und zum SEPA Mandat/Bankeinzug vorliegen und werde die unterschriebenen Papiere unverzüglich an Allianz Esa übermitteln“
      </p> -->
      <div class="d-flex justify-content-end py-4 proposal-policy-buttons">
<!--        <button class="btn btn-primary"-->
<!--                type="button"-->
<!--                @click="onGeneratePdfClick"-->
<!--                :disabled="!this.id">Vorschlag SEPA</button>-->
        <a :href="WEBSITE_PUBLIC_URL + 'files/pdf/' + pdf" target="_blank" class="btn btn-primary" :class="{disabled: pdf === ''}">Zusammenfassung als PDF speichern</a>
        <button :disabled="isPolicyComplete || statusForPolice" class="btn btn-primary" :class="{disabled: pdf === ''}" @click="onRequestProposalClick">
          Vorschlag anfordern
        </button>
        <button :disabled="DisabledPoliceAbschlieben || statusForPolice" @click="policyOpen" class="btn btn-primary" :class="{disabled: pdf === ''}">
          Police abschließen
        </button>
        <button class="btn btn-primary" @click="onPolicyComplete">Speichern und schließen</button>
      </div>
      <div class="custom__alert" :class="{ active: isStatusForPolice}">
        Die Police wurde bereits abgeschlossen/versendet. Ein erneuter Abschluss/Versand ist nicht möglich
      </div>
      <div class="custom__alert" :class="{ active: customerData.country !== 'Deutschland' }">
        Firmensitz außerhalb Deutschland. Weitere Prüfung erforderlich
      </div>
    </div>

    <modal :isOpen="isEmailOpen" @close="isEmailOpen = !isEmailOpen">
      <label class="blue__label text-center">An welche Adresse soll der Vorschlag gesendet werden?</label>
      <input class="form-control" v-model="proposalEmail" placeholder="E-mail Adresse eintragen" type="email"  />
      <template #footer>
        <button class="btn btn-primary" @click="onProposalEmail">Weiter</button>
      </template>
    </modal>

    <modal :isLarge="true" :isOpen="isPolicyOpen" @close="isPolicyOpen = !isPolicyOpen">
      <template #header>
        <h5 class="modal-title blue__label font-18">Versicherung beantragen</h5>
      </template>

      <template>
        <p class="transport__label">
          Hiermit wird der Abschluss der Versicherung beantragt.
        </p>
        <p class="transport__label">
          Die für den Abschluss der Versicherung erforderlichen Angaben wurden in der Risikoerfassung gemacht.
        </p>
        <p class="transport__label">
          Die Erklärungen zur Datenverarbeitung, Hinweise zu den Vertragsgrundlagen und Widerrufsrecht sowie sonstige Versicherungsinformationen sind dem Vorschlag bzw. der Police zu entnehmen und stehen als Download auf der Homepage
          <a href="https://www.allianz-esa.de/">www.allianz-esa.de</a>
          zur Verfügung.
        </p>
        <hr />
        <div class="row">
          <div class="col-12 col-sm-7">
            <h5 class="blue__label">
              Bitte erfassen Sie eine E-Mail-Adresse.
            </h5>
            <p>
              An diese E-Mail-Adresse verschicken wir die „Zusammenfassung der Angaben (Vorschlag) inklusive dem Preis“, die „Versicherungsbedingungen“, das „Produkt-Informations-Blatt“ sowie die „Erklärungen und Hinweise zum Antrag auf Abschluss einer Versicherung“.
            </p>
          </div>
          <div class="col-12 col-sm-5 text-right pt-3">
            <input class="form-control" placeholder="E-mail Adresse eintragen" type="email" v-model="proposalEmail"  />
            <button @click="onProposalEmailModal" class="btn btn-primary mt-3">
              Vorschlag per E-Mail
            </button>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="row align-items-center">
          <div class="col-12 col-sm">
            <p class="blue__label">
              Sobald die E-Mail durch Klick auf den Button „Vorschlag per E-Mail“ versendet wurde, kann die Police sofort abgeschlossen werden.
            </p>
          </div>
          <div class="col-12 col-sm text-right d-flex justify-content-center align-items-end flex-column">
            <button :disabled="!hasEmailSent || statusForPolice" @click="toggleSignaturePad" class="btn btn-lg btn-success rounded-0">
              Unterschrift digital
            </button>
            <button @click="onProposalEmailPolice" :disabled="!hasEmailSent || statusForPolice" class="btn btn-primary">
              Police abschließen
            </button>
          </div>
        </div>
      </template>
    </modal>

    <modal :isOpen="isQrCodeOpen" @close="isQrCodeOpen = !isQrCodeOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Bitte scannen Sie den QR-Code mit der Kamera Ihres Handys.
        </h5>
      </template>
      <div class="row">
        <div class="col-12">
          <p class="transport__label">Sie können dann die Unterschrift auf Ihrem Handy leisten.</p>
        </div>
        <div class="col-12 text-center">
          <qrcode v-if="isQrCodeOpen" :options="{ width: 300, height: 300 }" :value="qrValue" />
        </div>

        <div class="col-12 text-center pt-3">
          Versenden des QR-Code per E-mail.
          <input class="form-control" placeholder="E-mail Adresse eintragen" type="email" v-model="proposalEmail" />
          <button @click="sendDigitalSignEmail" class="btn btn-primary mt-3">
            QR-Code senden
          </button>
        </div>
      </div>

      <template #footer>
        <button @click="isQrCodeOpen = !isQrCodeOpen" class="btn btn-danger">Schließen</button>
      </template>
    </modal>

    <modal :isOpen="isMsgOpen" @close="isMsgOpen = !isMsgOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Vielen Dank für den Abschluss der Transport Versicherung.
        </h5>
      </template>
      <img :src="signature" class="signature__img" />
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformation erhalten Sie auch per Klick auf „Zusammenfassung als PDF speichern“. Sämtliche Daten und Informationen zu Ihrem Antrag stehen auch im Reiter „Bedingungen / Dateien“ zum Download bereit.
      </p>
      <p>
        Die Police werden wir zeitnah ausfertigen und Ihnen auf dem vereinbarten Zustellweg (Post, Mail, Portal) zur Verfügung stellen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isMsgOpen = !isMsgOpen">Fenster schließen</button>
      </template>
    </modal>
    <modal :isOpen="isConcludegOpen" @close="isConcludegOpen = !isConcludegOpen">
      <template #header>
        <h5 class="modal-title blue__label">
          Vielen Dank für den Abschluss der Transport Versicherung.
        </h5>
      </template>
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformation erhalten Sie auch per Klick auf „Zusammenfassung als PDF speichern“. Sämtliche Daten und Informationen zu Ihrem Antrag stehen auch im Reiter „Bedingungen / Dateien“ zum Download bereit.
      </p>
      <p>
        Die Police werden wir zeitnah ausfertigen und Ihnen auf dem vereinbarten Zustellweg (Post, Mail, Portal) zur Verfügung stellen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isConcludegOpen = !isConcludegOpen">Fenster schließen</button>
      </template>
    </modal>
    <modal :isOpen="isOpenFeedbackPopup" @close="isOpenFeedbackPopup = !isOpenFeedbackPopup">
      <template #header>
        <h5 class="modal-title blue__label">
          Der Vorschlag zur Versicherung wurde soeben per E-Mail zugestellt.
        </h5>
      </template>
      <p class="transport__label">
        Den Preis sowie alle erfassten Risikoinformationen erhalten Sie auch per Klick auf “Zusammenfassung als PDF speichern”.
      </p>
      <p>
        Die Police können Sie direkt per Klick auf “Police abschließen” beantragen. Dies kann auch direkt mit einer digitalen Unterschrift erfolgen.
      </p>
      <template #footer>
        <button class="btn btn-primary" @click="isOpenFeedbackPopup = !isOpenFeedbackPopup">Fenster schließen</button>
      </template>
    </modal>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import QrCode from '@chenfengyuan/vue-qrcode';
import Modal from '@/components/shared/modal.vue';
import InsuranceData from '@/components/insurance-data.vue';
import isMobile from '@/helper/is-mobile';
import priceCalculator from '@/service/price-calculator';
import pusher from '@/config/pusher';
import { WEBSITE_URL, WEBSITE_PUBLIC_URL, CASE_STATUS } from '@/constant/constants';

Vue.component(QrCode.name, QrCode);

export default Vue.extend({
  name: 'proposal-policy',
  components: {
    Modal,
    InsuranceData,
  },
  watch: {
    isConcludegOpen(value) {
      if (!value) {
        this.isPolicyOpen = false;
      }
      return value;
    },
    isPolicyOpen(value) {
      if (!value) {
        this.hasEmailSent = false;
      }
      return value;
    },
    isMsgOpen(value) {
      if (!value) {
        this.isQrCodeOpen = false;
      }
      return value;
    },
  },
  props: ['response', 'customerData', 'caseStatus', 'id', 'isAPIObserver'],
  data() {
    return {
      isCollapseActive: false,
      isStatusForPolice: false,
      isEmailOpen: false,
      isPolicyOpen: false,
      hasEmailSent: false,
      isQrCodeOpen: false,
      isMobile: false,
      isMsgOpen: false,
      isConcludegOpen: false,
      isOpenFeedbackPopup: false,
      qrValue: '',
      proposalEmail: this.response.form_data.customerData.user.email,
      // statusIns: this.response.status,
      priceIsFilled: this.response.price,
      manuallyPrice: this.response.manually_price_data,
      pdf: '',
      signature: '',
      isPolicyComplete: false,
      emailSubject: 'Vorschlag - Allianz Esa',
      WEBSITE_PUBLIC_URL,
    };
  },
  computed: {
    priceComputed() {
      return !(this.response.form_data.price_form_data.noPrice || (this.priceIsFilled === 0 && this.manuallyPrice === null));
    },
    DisabledPoliceAbschlieben() {
      return (this.isPolicyComplete || this.customerData.country !== 'Deutschland' || (this.priceIsFilled === 0 && this.manuallyPrice === null));
    },
    statusForPolice() {
      return !(this.caseStatus === CASE_STATUS.OFFER || this.caseStatus === CASE_STATUS.OFFER_REQUEST || this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY || this.caseStatus === CASE_STATUS.SUGGESTION);
    },
  },
  methods: {
    policyOpen() {
      this.getStatusInsuranceFromBackend();
      if (!this.statusForPolice) {
        this.isPolicyOpen = true;
      } else {
        this.isStatusForPolice = true;
      }
    },
    async onGeneratePdfClick() {
      priceCalculator.generatePDF(this.id).then((response) => {
        window.open(`${WEBSITE_URL}generate-pdf-direct-debit/${this.id}`);
      });
    },
    toggleSignaturePad() {
      this.isPolicyOpen = false;
      this.isQrCodeOpen = true;
    },
    onRequestProposalClick() {
      this.getStatusInsuranceFromBackend();
      if (!this.statusForPolice) {
        this.isEmailOpen = true;
      } else {
        this.isStatusForPolice = true;
      }
      if (!this.priceComputed) {
        // priceCalculator.sendEmailToAdmin({
        //   id: this.response.id,
        // });
        this.$emit('onOfferRequest');
      }
    },
    onProposalEmail() {
      this.hasEmailSent = true;
      priceCalculator.sendProposalEmail({
        email: this.proposalEmail,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'vorschlag',
      })
        .finally(() => {
          this.isEmailOpen = false;
          this.isOpenFeedbackPopup = true;
        });
    },
    onProposalEmailModal() {
      this.hasEmailSent = true;
      this.$emit('case-status-change', CASE_STATUS.SUGGESTION); // set status
      priceCalculator.sendProposalEmail({
        email: this.proposalEmail,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'beantragen-vorschlag',
      });
    },
    onProposalEmailPolice() {
      this.isPolicyComplete = true;
      this.isConcludegOpen = true;
      this.hasEmailSent = true;
      this.$emit('case-status-change', CASE_STATUS.CONTRACT_NO_SIGNATURE); // set status
      priceCalculator.sendProposalEmail({
        email: this.proposalEmail,
        id: this.response.id,
        pdf: this.pdf,
        btn: 'police-vorschlag',
        status: CASE_STATUS.CONTRACT_NO_SIGNATURE,
      }).then((response) => {
        if (response.data.error !== undefined) {
          this.$emit('case-status-change', response.data.status); // set status
        }
      });
    },
    sendDigitalSignEmail() {
      priceCalculator.emailDigital({
        email: this.proposalEmail,
        link: this.qrValue,
        id: this.response.id,
      });
    },
    onPolicyComplete() {
      this.isPolicyComplete = true;
      this.$router.push('/products');
      // router.push({ path: 'products' });
    },
    onGeneratePDF() {
      priceCalculator.generatePDFforUser(this.id).then((response) => {
        // console.log(response);
        const pdfName = response.data;
        this.pdf = pdfName;
      });
      this.$emit('onAddPdf', this.pdf);
    },
    getStatusInsuranceFromBackend() {
      priceCalculator.getStatusInsurance(this.id).then((response) => {
        this.$emit('case-status-change', response.data);
        return (response.data === CASE_STATUS.OFFER || response.data === CASE_STATUS.OFFER_REQUEST || response.data === CASE_STATUS.OFFER_REQUEST_READY || response.data === CASE_STATUS.SUGGESTION);
      });
    },
  },
  // beforeMount() {
  //   this.onGeneratePDF();
  // },
  beforeDestroy() {
    pusher.unbind('userSigned');
  },
  mounted() {
    this.$emit('get-status-insurance', this.id);
    this.onGeneratePDF();
    this.getStatusInsuranceFromBackend();
    this.isMobile = isMobile();
    this.qrValue = `${window.location.origin}/signature-pad/${this.response.id}`;

    pusher
      .subscribe(`sign-channel.${localStorage.getItem('id')}`)
      .bind('userSigned', (response) => {
        this.signature = response.signImage;
        console.log(response.connectAMSstatus);
        this.isMsgOpen = true;
        this.$emit('case-status-change', CASE_STATUS.CONTRACT_NO_SIGNATURE); // set status
        this.isPolicyComplete = true;
        this.$emit('on-digital-sign');
      });
  },
});
</script>

<style lang="scss" scoped>
.signature__img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  object-position: center;
  object-fit: contain;
}
  .disabled{
    opacity: 0.5;
  }
</style>
